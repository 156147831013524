import Contract from './contract'
import addresses from './settings/addresses'
import helpers from "./helpers";
import connection from "./connection";
import Web3 from "web3";

const getAcc = async () => {
    let account = await connection.getAccount()
    if (account) {
        return account.full
    }
    return null;
}


const getCurrentBalance = async () => {

    let address = await getAcc();
    let token = await returnERC20Contract();
    let balance = await token.balanceOf([address]);
    return helpers.formatAmount(balance);
};

const getTokensStaked = async () => {
    let account = await getAcc();
    let stakingContract = returnStakingContract();
    let balance = await stakingContract.balanceOf([account]);
    return helpers.formatAmount(balance);
}


// const sendTokenApprove = async (amount) => {
//
//     let address = await getAcc()
//     let token = await returnERC20Contract();
//     return await token.approve([address, amount]);
// }


const apr = async () => {
    let stakingContract = returnStakingContract();
    return (await stakingContract.apr()) / 100;
}


const stakeTokensPRE = async (amount) => {


    let tokenContract = returnERC20Contract();
    let stakingContract = returnStakingContract();


    let spender = addresses.getAddress(connection.getNetwork(), 'staking')
    let approveTx = await tokenContract.approve([spender, amount]);
    if (approveTx) {
        let stakeTx = await stakingContract.stake([amount]);
        return await stakeTx;
    }
    return null;

}

const checkAllowance = async () => {
    let tokenContract = returnERC20Contract();
    let spender = addresses.getAddress(connection.getNetwork(), 'staking')
    let account = await getAcc();
    return await tokenContract.allowance([account, spender])
}

const stakeTokens = async (amount) => {

    let tokenContract = returnERC20Contract();
    let stakingContract = returnStakingContract();

    let spender = addresses.getAddress(connection.getNetwork(), 'staking')

    let hasAllowance = await checkAllowance();
    if (hasAllowance > 0) {
        if (helpers.isLTE(amount, hasAllowance)) {
            let stakeTx = await stakingContract.stake([amount]);
            return await stakeTx;
        }
    }
    //Amount to 15bn
    // '15000000000-000000000000000000'
    let approveTx = await tokenContract.approve([spender, '15000000000000000000000000000']).then(async function(){
        // console.log(receipt);
        let stakeTx = await stakingContract.stake([amount]);
        return await stakeTx;
    });
    return !!approveTx;

}

const getBalanceAndRewards = async () => {
    let stakingContract = await returnStakingContract();
    let account = await getAcc();
    let earned = await stakingContract.earned([account])

    let balance = await stakingContract.balanceOf([account])
    let web3 = new Web3()
    let BN = web3.utils.BN;
    let total = new BN(earned).add(new BN(balance)).toString();
    return helpers.formatAmount(total);
}


const unStakeTokens = async (amount) => {

    if (amount === -1) {
        amount = await getBalanceAndRewards();
    }

    let stakingContract = await returnStakingContract();
    let staked = stakingContract.stake([amount]);
    return staked;
}

async function cashOutRewardsOnly()
{
    let stakingContract = returnStakingContract();
    let rewardTx = await stakingContract.getReward();
    return await rewardTx;
}

async function unStake()
{
    let stakingContract = returnStakingContract();
    let rewardTx = await stakingContract.withdraw();
    return await rewardTx;
}

async function cashOutAll()
{
    let stakingContract = returnStakingContract();
    let rewardTx = await stakingContract.exit();
    return await rewardTx;
}


async function getApr()
{

    let stakingContract = returnStakingContract();
    let rewardRate = await stakingContract.rewardRate();
    let totalSupply = await stakingContract.totalSupply();
    return helpers.APRCalc(rewardRate, totalSupply)

    // return (await stakingContract.apr()) / 100;
}

async function getEarned()
{
    let account = await getAcc();
    let stakingContract = returnStakingContract();
    let earned = await stakingContract.earned([account])
    return helpers.formatAmount(earned);
}

const returnStakingContract = () => {
    let account = addresses.getAddress(connection.getNetwork(), 'staking')
    return new Contract('staking', account)
}

const returnERC20Contract = () => {
    let account = addresses.getAddress(connection.getNetwork(), 'erc20')
    return new Contract('erc20', account)
}

export default {
    getAcc,
    getCurrentBalance,
    getTokensStaked,
    stakeTokens,
    stakeTokensPRE,
    unStakeTokens,
    getApr,
    getEarned,
    getBalanceAndRewards,
    cashOutRewardsOnly,
    unStake,
    cashOutAll,
    apr,
}